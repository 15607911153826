import React from 'react'
import { Link } from 'react-router-dom'
import banner from "../../../../../Assets/Images/Home/banner.jpeg"
import "./style.scss"
function HeroSection() {
    return (
        <div className='heroSection'>
            <div className="heroSection-img">
                <img src={banner} alt="" />
            </div>
            <div className="heroSection-content">
                <div className="title">
                    Spread worths
                </div>


                <Link to="" className="button">
                    <span>more</span>
                </Link>
            </div>
        </div>
    )
}

export default HeroSection