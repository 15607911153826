import React from 'react'
import "./style.scss"
interface Props {
    title: string;
    detail: string;
    type: 'danger' | 'primary' 
}
function TitleMain({title,detail,type}:Props) {
  return (
    <>
      <div className={`titleMain ${type}`}>
        <span>{title}</span>
      </div>
      <div className="titleMain-detail">
        <span>{detail}</span>
      </div>
    </>
  )
}

export default TitleMain