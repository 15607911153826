import React from "react";
import "./style.scss";
import TitleMain from "../TitleMain/TitleMain";
import img1 from "../../../../../Assets/Images/Study/study-map-1.png";
import img2 from "../../../../../Assets/Images/Study/map-image-1.png";
import { useTranslation } from "react-i18next";

const data = {
    enery: {
        detail: "Study_Des_5",
        title: "Study_Des_5_1",
    },
    weight: {
        detail: "Study_Des_6",
        title: "Study_Des_6_1",
    },
    improve: {
        detail: "Study_Des_7",
        title: "Study_Des_7_1",
    },
    protects: {
        detail: "Study_Des_8",
        title: "Study_Des_8_1",
    },
    health: {
        detail: "Study_Des_9",
        title: "Study_Des_9_1",
    },
};
function Map() {
    const { t, i18n } = useTranslation(["des"]);
    return (
        <>
            <div className="map">
                <div className="map-container">
                    <div className="title">
                        <span>{t("Study_Des_4")}</span>
                    </div>
                    <div className="map-study-enery">
                        <TitleMain type="primary" detail={t(data.enery.detail)} title={t(data.enery.title)} />
                    </div>

                    <div className="map-study-weight">
                        <TitleMain type="primary" detail={t(data.weight.detail)} title={t(data.weight.title)} />
                    </div>

                    <div className="map-study-img">
                        <img src={img1} alt="" />
                    </div>

                    <div className="map-study-improve">
                        <TitleMain type="primary" detail={t(data.improve.detail)} title={t(data.improve.title)} />
                    </div>

                    <div className="map-study-protects">
                        <TitleMain type="primary" detail={t(data.protects.detail)} title={t(data.protects.title)} />
                    </div>

                    <div className="map-study-img">
                        <img src={img2} alt="" />
                    </div>

                    <div className="map-study-health">
                        <TitleMain type="primary" detail={t(data.health.detail)} title={t(data.health.title)} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Map;
