import docs_6 from "../../../Assets/Images/FAQ/docs.jpg";
import safely_en from "../../../Assets/Images/FAQ/safely_en.jpeg";
import safely_kr from "../../../Assets/Images/FAQ/safely_kr.jpeg";
export const faqList: any[] = [
    {
        id: 0,
        question: "What is your NMN raw material?",
        answer: "lalavita ™ uses Uthever® NMN G2 graded powder as raw material. The G2 powder has a large volume compared to the particle size, so a sufficient amount of NMN can be taken with a smaller dose. Also, it tastes better than other raw materials when taken in powder form.",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 1,
        question: "Why is ‘Resveratrol’ in this NMN? Wouldn't it be better if it contained NMN only?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 2,
        question: "Is this brand trustworthy?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 3,
        question: "What is NMN made from?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 4,
        question: "Why should I take NMN?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 5,
        question: "Is it a reliable raw material?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 6,
        question: "Is the NMN purity certified by an independent lab?",
        answer: "-",
        active: false,
        img_kr: docs_6,
        img_en: docs_6,
        sub_text: false,
    },
    {
        id: 7,
        question: "A sublingual supplement is my first time. How is it different from capsules?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 8,
        question: "Does this NMN supplement need to be refrigerated?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 9,
        question: "At what age should I start taking NMN?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 10,
        question: "Can I take NMN while pregnant?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 11,
        question: "Does NMN have side effects?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
    {
        id: 12,
        question: "What should one be aware of when taking NMN safely?",
        answer: "-",
        active: false,
        img_kr: safely_kr,
        img_en: safely_en,
        sub_text: true,
    },
    {
        id: 13,
        question: "Is this product Non-GMO?",
        answer: "-",
        active: false,
        img_kr: "",
        img_en: "",
        sub_text: false,
    },
];
