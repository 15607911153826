import React from "react";
import SubTitle from "../../Common/SubTitle/SubTitle";
import "./Shop.scss";
import box from "../../../Assets/Images/Shop/img.png";
import img1 from "../../../Assets/Images/Shop/amazon_logo.png";
import img2 from "../../../Assets/Images/Shop/iherb.png";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer/Footer";

function Shop() {
    return (
        <>
            <SubTitle title="Shop" display={true} />
            <div className="shop">
                <div className="shop-container">
                    <div className="item_01">
                        <img src={box} alt="" className="shop-img" />
                    </div>
                    <div className="shop_info">
                        <div className="shop_info_head">
                            <h4>lalavita Supercondria NMN Powder 100g</h4>
                            <p>Anti-Aging NAD + Uthever NMN, Heart Health</p>
                        </div>
                        <div className="shop_info_detail">
                            <div className="shop_info_detail_item">
                                <p>Item Form</p>
                                <span>Powder</span>
                            </div>
                            <div className="shop_info_detail_item">
                                <p>Item Weight</p>
                                <span>0.1 Kilograms</span>
                            </div>
                            <div className="shop_info_detail_item">
                                <p>Seller</p>
                            </div>
                            <div className="shop_info_detail_item">
                                <div className="shop_info_detail_seller button">
                                    <div className="box-pink">
                                        <a
                                            className="amazon"
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.amazon.com/dp/B0BJ5XQSQ5/ref=sr_1_17_sspa?crid=GEEG3ZZCPVQS&keywords=nmn&qid=1670477787&sprefix=%2Caps%2C364&sr=8-17-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFNWVNEM1VaNU9SWFgmZW5jcnlwdGVkSWQ9QTAzNzcxNTExQkY1UkdPWlA3QlBVJmVuY3J5cHRlZEFkSWQ9QTAwODI3OTczSFhKTEpVT1JSU1JGJndpZGdldE5hbWU9c3BfbXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ=="
                                        >
                                            <img src={img1} alt="amazon" />
                                        </a>
                                    </div>
                                    {/* <div className="box-pink">
                                        <a className="iherb" target="_blank" rel="noreferrer" href="/">
                                            <img src={img2} alt="iherb" />
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Shop;
