import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <div className="leftItem">
          <div className="logo">NANUMCNC</div>

          <div className="detail">
            사업자등록번호 : 105-86-57699<br />
            대표 : 고정혁
            <br />
            주소 : 서울특별시 종로구 새문안로3길 15 9층
            <br />
            NANUMCNC. ALL RIGHTS RESERVED
          </div>

          <ul className="list">
            <li>
              <Link to="">서비스이용약관</Link>
            </li>
            <li>
              <Link to="">개인정보처리방침</Link>
            </li>
            <li>
              <Link to="">고객센터</Link>
            </li>
          </ul>
        </div>

        <ul className="rightItem">
          <li><Link to="">네이버 블로그</Link></li>
          <li><Link to="">페이스북</Link></li>
          <li><Link to="">유튜브</Link></li>
          <li><Link to="">인스타그램</Link></li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
