import React from "react";
import { Link } from "react-router-dom";
import arrowRight from "../../../../../Assets/Images/Home/arrow-right.png";
import "./style.scss";

interface Props {
  className: string;
  bgColor: string;
  title: string;
  img: any;
  subImg: any;
  link: string;
  isReverse: boolean;
  imgFlexEnd?: boolean;
}
function SharedComponent({
  className,
  bgColor,
  title,
  img,
  subImg,
  link,
  isReverse = false,
  imgFlexEnd,
}: Props) {
  return (
    <div style={{ background: bgColor }} className={"sharedComponent " + className}>
      <div
        style={{ flexDirection: !isReverse ? "row" : "row-reverse" }}
        className="sharedComponent-container"
      >
        <div className="leftItem">
          <div className="title">{title}</div>
          <div className="detail">
            <div className="img">
              <img src={subImg} alt="" />
            </div>
            <Link
              style={{ borderWidth: bgColor === "#FFFFFF" ? 1 : 0 }}
              to={"/"}
              className="button"
            >
              <span>more</span>
              <img src={arrowRight} alt="" />
            </Link>
          </div>
        </div>
        <div
          style={{ alignSelf: imgFlexEnd ? "flex-end" : "center" }}
          className="rightItem"
        >
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
}

export default SharedComponent;
