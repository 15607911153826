import React, { useEffect, useRef, useState } from "react";
import SubTitle from "../../Common/SubTitle/SubTitle";
import arrow from "../../../Assets/Images/FAQ/arrow.png";
import { faqList } from "./data";
import "./FAQ.scss";
import Footer from "../../Common/Footer/Footer";
import { useTranslation } from "react-i18next";
interface IFAQ {
    id: number;
    question: string;
    answer: string;
    active: boolean;
    img_en: string;
    img_kr: string;
    sub_text: boolean;
}
function FAQ() {
    const { t, i18n } = useTranslation(["faq"]);
    const [active, setActive] = useState(-1);
    const [animation, setAnimation] = useState(-1);
    const listRef = useRef<any>([]);

    const handleActiveButton = (index: number) => {
        if (!listRef || !listRef.current[index]) {
            // useRef 변수가 비었을 때
            return; // 그냥 리턴하도록 예외처리를 해주자
        }

        const style = listRef.current[index].style; // 접근할 DOM 요소의 스타일 속성을 미리 선언해둔다.

        if (index === active) {
            // closeAllList 상태변수가 true 일 때
            setActive(-1);
            style.maxHeight = "0"; // maxHeight 는 0이 되고 접힌다.
            return;
        } else if (index !== active) {
            // closeAllList 상태변수가 false 면
            listRef.current.map((item: any) => {
                item.style.maxHeight = "0";
            });
            style.maxHeight = `${listRef.current[index].scrollHeight}px`; // maxHeight = scroll 길이가 되고 메뉴가 열린다.
            setActive(index); // 클릭할 때마다 상태를 반대로 바꾼다.
        }
    };

    return (
        <>
            <SubTitle title="FAQ" display={true} />

            <div className="faq">
                <div className="faq-container">
                    <div className="document-list">
                        {faqList !== undefined &&
                            faqList.map((item: IFAQ, index: number) => {
                                return (
                                    <div key={item.id} className={"document" + (active === index ? " open" : " close")}>
                                        <div
                                            className="title"
                                            onClick={() => {
                                                handleActiveButton(index);
                                            }}
                                        >
                                            <div>{t("faq_q_" + index)}</div>
                                            <img src={arrow} alt="" />
                                        </div>
                                        <div ref={(el) => (listRef.current[index] = el)} className={"answer"}>
                                            <p>
                                                {t("faq_a_" + index)}
                                                {item.img_en && item.img_kr && <img src={i18n.language === "en" ? item.img_en : item.img_kr} alt=""></img>}
                                                {item.sub_text && t("faq_a_" + index + "_sub")}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        <div className="bottom"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default FAQ;
