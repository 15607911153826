import React from "react";
import "./SectionFour.scss";
import img1 from "../../../../../Assets/Images/SuperCondria/section4/NMN_0.png";
import img2 from "../../../../../Assets/Images/SuperCondria/section4/NMN_1.png";
import img3 from "../../../../../Assets/Images/SuperCondria/section4/NMN_2.png";
import img4 from "../../../../../Assets/Images/SuperCondria/section4/NMN_3.png";
import img5 from "../../../../../Assets/Images/SuperCondria/section4/NMN_4.png";
import { useTranslation } from "react-i18next";

const itemList = [
  {
    index: 0,
    img: img1,
    heading: "SectionFour_Des_1",
    desc: "SectionFour_Des_1_1",
  },
  {
    index: 1,
    img: img2,
    heading: "SectionFour_Des_2",
    desc: "SectionFour_Des_2_1",
  },
  {
    index: 2,
    img: img3,
    heading: "SectionFour_Des_3",
    desc: "SectionFour_Des_3_1",
  },
  {
    index: 3,
    img: img4,
    heading: "SectionFour_Des_4",
    desc: "SectionFour_Des_4_1",
  },
  {
    index: 4,
    img: img5,
    heading: "SectionFour_Des_5",
    desc: "SectionFour_Des_5_1",
  },
];

function SectionFour() {
  const { t, i18n } = useTranslation(["des"]);
  return (
    <div className="section-fourth_v2">
      <div className="section-fourth_v2-container border-section container">
        <h3 className="h3">{t("SectionFour_Des_0")}</h3>
        <div className="section-fourth_v2-list">
          {itemList.map((item) => (
            <div key={item.index} className="section-fourth_v2-item">
              <img src={item.img} alt="img section4" />
              <h4 className={i18n.language == "en" ? "en" : "kr"}>{t(item.heading)}</h4>
              <p>{t(item.desc)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SectionFour;
