import React from "react";
import { useTranslation } from "react-i18next";
import logo1 from "../../../../../Assets/Images/SuperCondria/section2/Main_2_1.png";
import logo1Kr from "../../../../../Assets/Images/SuperCondria/section2/Main_2_1_kr.png";
import logo2 from "../../../../../Assets/Images/SuperCondria/section2/Main_2_2.png";

import "./SectionTwo.scss";

function SectionTwo() {
  const { t, i18n } = useTranslation(["des"]);
  return (
    <div className="section-two_v2">
      <div className="section-two_v2-container border-section container">
        <div className="title">
          <img src={i18n.language == "en" ? logo1 : logo1Kr} alt="" />
        </div>
        <div className="detail">
          <img src={logo2} alt="" />
        </div>
      </div>
    </div>
  );
}

export default SectionTwo;
