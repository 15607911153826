import React, { useState } from "react";
import "./SectionFive.scss";
import logo2 from "../../../../../Assets/Images/SuperCondria/section5/NMN_Human.png";
import { useTranslation } from "react-i18next";

function SectionFive() {
  const { t, i18n } = useTranslation(["des"]);
  return (
    <div className="section-five_v2">
      <div className="section-five_v2-container border-section container">
        <h3 className="h3">{t("SectionFive_Des_0")}</h3>
        <div className="section-five_v2-content">
          <h3 className="h3">
            {t("SectionFive_Des_1")}
          </h3>
          <p>{t("SectionFive_Des_2")}</p>
        </div>
        <div className="section-five_v2-box">
          <img src={logo2} alt="" />
          <div className="section-five_v2-box-white">
            <p>
              {t("SectionFive_Des_3")}
            </p>
            <p>
              {t("SectionFive_Des_4")}
            </p>
            <p>
              {t("SectionFive_Des_5")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionFive;
