import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./header.scss";

const Header: React.FC = () => {
  const [langActive, setLangActive] = useState("ENG");
  const [navbar, setNavbar] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLangActive(i18next.language);
  }, []);

  const handleChangeLang = (lang: string) => {
    if (lang === "ENG") {
      i18next.changeLanguage("KOR");
    } else {
      i18next.changeLanguage("ENG");
    }
    langUIActive();
  };
  const langUIActive = () => {
    setLangActive(i18next.language);
  };

  const changBackground = () => {
    if (window.scrollY > 8) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changBackground);

  return (
    <div
      style={{ position: "fixed" }}
      className={`header ${navbar ? "activeNav" : ""}`}
    >
      <div className="header-container">
        <div className="header_menu">
          <div className="header_title">
            <a href="/" className="logo">
              <span>NANUMCNC</span>
            </a>
          </div>
          {/* //header menu  */}
          <ul className="list_menu">
            {data.map((item, index) => (
              <li key={index} className="menu_items">
                <Link
                  to={item.pathname}
                >
                  {item.tit}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="header_lang">
          <Link
            style={{ borderColor: navbar ? "#000" : "#fff" }}
            to=""
            className="header_lang_button"
          >
            <span>회사소개</span>
          </Link>
          <span>|</span>
          <div
            onClick={() => handleChangeLang(langActive)}
            className="header_lang_choooseLang"
          >
            <span>{langActive}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
        </div>

        <div className="menu-mobile-lang">
          <div
            onClick={() => setOpenMenu(!openMenu)}
            className={"menu-icon" + (openMenu ? " check" : "")}
          >
            {[1,2,3].map((item,index) => (
              <span style={{background: navbar ? '#000' : "#fff"}} key={index}></span>
            ))}
          </div>
          <div className={`header-mobile ${openMenu ? "openMenu" : ""}`}>
            <Link
              to="/"
              className="logo"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <span>NANUMCNC</span>
            </Link>
            <div className="menu-mobile-items">
              <ul className="list_menu">
                {data.map((item, index) => (
                  <li key={index} className="menu_items">
                    <Link
                      to={item.pathname}
                      onClick={() => setOpenMenu(!openMenu)}
                    >
                      {item.tit}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="menu-mobile-lang">
              <div className="header_lang">
                <Link to="" className="header_lang_button">
                  <span>회사소개</span>
                </Link>
                <span>|</span>
                <div onClick={() => handleChangeLang(langActive)} className="header_lang_choooseLang">{langActive}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

const data = [
  {
    tit: "Lalavita",
    pathname: "/",
  },
  {
    tit: "Think Nature",
    pathname: "/",
  },
  {
    tit: "Kokofit",
    pathname: "/",
  },
  {
    tit: " Stargrow",
    pathname: "/",
  },
  {
    tit: "xo 55i",
    pathname: "/",
  },

  {
    tit: "Waterlove",
    pathname: "/",
  },
];
