import React from "react";
import "./style.scss";
import SubTitle from "../../../../Common/SubTitle/SubTitle";
import TitleMain from "../TitleMain/TitleMain";
import img1 from "../../../../../Assets/Images/Study/study-img-1.png";
import { useTranslation } from "react-i18next";

const data = {
    intro: {
        detail: "Study_Des_1",
        title: "Study_Des_1_1",
    },
    target: {
        detail: "Study_Des_2",
        title: "Study_Des_2_1",
    },
    challenge: {
        detail: "Study_Des_3",
        title: "Study_Des_3_1",
    },
};
function Intro() {
    const { t, i18n } = useTranslation(["des"]);
    return (
        <>
            <div className="intro-main">
                <div className="intro-main-container">
                    <div className="title">
                        <span>{t("Study_Des_0")}</span>
                    </div>
                    <div className="intro-main-main">
                        <TitleMain type="danger" detail={t(data.intro.detail)} title={t(data.intro.title)} />
                    </div>

                    <div className="intro-main-img">
                        <img src={img1} alt="" />
                    </div>

                    <div className="intro-main-target">
                        <TitleMain type="danger" detail={t(data.target.detail)} title={t(data.target.title)} />
                    </div>

                    <div className="intro-main-challenge">
                        <TitleMain type="danger" detail={t(data.challenge.detail)} title={t(data.challenge.title)} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
