import ReactFullpage from "@fullpage/react-fullpage";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SectionFive from "./Components/SectionFive/SectionFive";
import SectionFour from "./Components/SectionFour/SectionFour";
import SectionOne from "./Components/SectionOne/SectionOne";
import SectionThree from "./Components/SectionThree/SectionThree";
import SectionTwo from "./Components/SectionTwo/SectionTwo";
import SectionSix from "./Components/SectionSix/SectionSix";
import SubTitle from "../../Common/SubTitle/SubTitle";
import { useLocation } from "react-router-dom";
import line from "../../../Assets/Images/Line.png";
import "./index.scss";
import Footer from "../../Common/Footer/Footer";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function Home(props: any) {
    const fullpageOptions = {
        anchors: ["firstPage", "secondPage", "thirdPage", "fourthPage", "fivePage", "sixPage", "sevenPage"],
    };
    const location = useLocation();

    const param = `${location.pathname}${location.hash}` === `${location.pathname}#firstPage`;

    return (
        <>
            <SubTitle display={param || location.hash === "" ? true : false} title="SUPERCONDRIA® NMN" />

            <div className="page-home" id="superCondria">
                {/* <ReactFullpage
        licenseKey={"263DXXXX-B839XXXX-AE67XXXX-F398XXXX"}
        {...fullpageOptions}
        navigation={true}
        scrollingSpeed={700}
        normalScrollElements={".itemList"}
        cards={false}
        cardsOptions={{
          perspective: 100,
          fadeContent: true,
          fadeBackground: true,
        }}
        render={() => {
          return ( */}
                <>
                    <div className="section">
                        <SectionOne />
                        <img className="section-line" src={line} alt="line" />
                    </div>

                    <div className="section">
                        <SectionTwo />
                        <img className="section-line" src={line} alt="line" />
                    </div>

                    <div className="section">
                        <SectionThree />
                        <img className="section-line" src={line} alt="line" />
                    </div>

                    <div className="section">
                        <SectionFour />
                        <img className="section-line" src={line} alt="line" />
                    </div>

                    <div className="section">
                        <SectionFive />
                        <img className="section-line" src={line} alt="line" />
                    </div>

                    <div className="section">
                        <SectionSix />
                    </div>
                </>
                {/* );
        }}
      /> */}
            </div>
            <Footer />
        </>
    );
}

export default Home;
