import React from "react";
import Footer from "../../Common/Footer/Footer";
import SubTitle from "../../Common/SubTitle/SubTitle";
import Intro from "./components/Intro/index";
import Map from "./components/Map/index";
import Reference from "./components/Reference";
function index() {
    return (
        <>
            <SubTitle display={true} title="Study" />
            <Intro />
            <Map />
            <Reference />
            <Footer />
        </>
    );
}

export default index;
