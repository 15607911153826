import "./SectionOne.scss";
import box from "../../../../../Assets/Images/SuperCondria/section1/NMN_Page_1.png";
import b1 from "../../../../../Assets/Images/SuperCondria/section1/brand1.png";
import b2 from "../../../../../Assets/Images/SuperCondria/section1/brand2.png";
import b3 from "../../../../../Assets/Images/SuperCondria/section1/brand3.png";
import b4 from "../../../../../Assets/Images/SuperCondria/section1/brand4.png";
import b5 from "../../../../../Assets/Images/SuperCondria/section1/brand5.png";
import b6 from "../../../../../Assets/Images/SuperCondria/section1/brand6.png";
import img1 from "../../../../../Assets/Images/SuperCondria/section1/NMN_Page_1-1.png";
import { useTranslation } from "react-i18next";

const listItem = [
    {
        index: 0,
        text: "Clinical_Proof_Des_Sub_0",
    },
    {
        index: 1,
        text: "Clinical_Proof_Des_Sub_1",
    },
    {
        index: 2,
        text: "Clinical_Proof_Des_Sub_2",
    },
    {
        index: 3,
        text: "Clinical_Proof_Des_Sub_3",
    },
];

const imgList = [
    {
        index: 0,
        img: b1,
    },
    {
        index: 1,
        img: b2,
    },
    {
        index: 2,
        img: b3,
    },
    {
        index: 3,
        img: b4,
    },
];

function SectionOne() {
    const { t, i18n } = useTranslation(["des"]);
    return (
        <div className="section-one_v2 bg-main">
            <div className="section-one_v2-container border-section container">
                <div className="img">
                    <img src={box} alt="" />
                </div>
                <div className="detail">
                    <img className="img-small" src={img1} alt="" />
                    <h2 className="detail-heading">{t("Clinical_Proof")}</h2>
                    <p>{t("Clinical_Proof_Des")}</p>
                    <ul>
                        {listItem.map((item) => (
                            <li key={item.index}>{t(item.text)}</li>
                        ))}
                    </ul>
                    <div className="detail_bottom">
                        <h2>Made in KOREA</h2>
                        <div className="detail_images">
                            {/* {imgList.map((item) => (
                                <img key={item.index} src={item.img} alt="brand" />
                            ))} */}
                            <img src={b6} alt="brand" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionOne;
