import { BoldOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";

import logo2 from "../../../../../Assets/Images/SuperCondria/section3/Main_2_2.png";
import logo2kr from "../../../../../Assets/Images/SuperCondria/section3/Main_2_2_kr.png";
import logo3 from "../../../../../Assets/Images/Home/section2/Main_2_2.png";
import "./SectionThree.scss";


const Section3 = () => {
  const { t, i18n } = useTranslation(["des"]);
  return (
    <div className="section-three_v2 bg-main">
      <div className="section-three_v2-container border-section container">
        {/* <div style={{ fontSize: "24px", fontWeight: "700" }}>{t("SectionThree_Des_0")}</div> */}
        <img src={i18n.language == "en" ? logo2 : logo2kr} alt="logo section3" />
      </div>
    </div >
  );
};

export default Section3;
