import React from "react";
import SharedComponent from "./Components/SharedComponent/SharedComponent";
import img1 from "../../../Assets/Images/Home/img-1.png";
import img2 from "../../../Assets/Images/Home/img-2.png";
import img3 from "../../../Assets/Images/Home/img-3.png";
import img4 from "../../../Assets/Images/Home/img-4.png";
import img5 from "../../../Assets/Images/Home/img-5.png";
import img6 from "../../../Assets/Images/Home/img-6.png";
import subImg1 from "../../../Assets/Images/Home/sub-img-1.png";
import subImg2 from "../../../Assets/Images/Home/sub-img-2.png";
import subImg3 from "../../../Assets/Images/Home/sub-img-3.png";
import subImg4 from "../../../Assets/Images/Home/sub-img-4.png";
import subImg5 from "../../../Assets/Images/Home/sub-img-5.png";
import subImg6 from "../../../Assets/Images/Home/sub-img-6.png";
import HeroSection from "./Components/HeroSection/HeroSection";

function Home() {
  return (
    <>
      <HeroSection />
      {data.map((item, index) => (
        <SharedComponent
          className={item.className}
          key={index}
          bgColor={item.bgColor}
          img={item.img}
          subImg={item.subImg}
          link={item.link}
          isReverse={item.isReverse}
          title={item.title}
          imgFlexEnd={item.imgFlexEnd}
        />
      ))}
    </>
  );
}

export default Home;

const data = [
  {
    className: "content_0",
    bgColor: "#FFEBF5",
    title: `Back to the\nYouth`,
    img: img1,
    subImg: subImg1,
    isReverse: false,
    link: "",
  },
  {
    className: "content_1",
    bgColor: "#FBF8EC",
    title: `Think nature \n do nature`,
    img: img2,
    subImg: subImg2,
    isReverse: true,
    link: "",
  },
  {
    className: "content_2",
    bgColor: "#FFFFFF",
    title: `Just the Right Fit for \n Baby and Mom`,
    img: img3,
    subImg: subImg3,
    imgFlexEnd: true,
    isReverse: false,
    link: "",
  },
  {
    className: "content_3",
    bgColor: "#EDF3BE",
    title: `'Five Star Plan'\n Nutrition Program \n for Baby`,
    img: img4,
    subImg: subImg4,
    isReverse: true,
    link: "",
  },
  {
    className: "content_4",
    bgColor: "#F5EACC",
    title: `Premium Baby\n Nutrition with the  \n Best Love`,
    img: img5,
    subImg: subImg5,
    isReverse: false,
    link: "",
  },
  {
    className: "content_5",
    bgColor: "#FFFFFF",
    title: `Everyday \n Water Love`,
    img: img6,
    subImg: subImg6,
    isReverse: true,
    link: "",
  },
];