import React from "react";
import { useLocation, useParams } from "react-router-dom";
import "./style.scss";
interface Props {
  title: string;
  display: boolean;
}
function SubTitle({ title, display }: Props) {
  const location = useLocation();


  return (
    <div
      className={`subtitle-page  ${
        location.pathname === "/shop" ? "box-pink" : ""
      }`}
      style={{
        display: display ? "block" : "none",
      }}
    >
      <div
        className={`subtitle-page_container ${
          location.pathname === "/shop" ? "none-border" : ""
        }`}
      >
        <p>{title}</p>
      </div>
    </div>
  );
}

export default SubTitle;
