import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import arrowDown from "../../../../../Assets/Images/Study/arrowdown2.png";
function Reference() {
    const data = [
        "[1] S. Tarantini, et al. Redox Biology 24 (2019) 101192",
        "[2] F. Berger, C. Lau, M. Dahlmann, M. Ziegler, J. Biol. Chem. 280, 36334–36341 (2005).",
        "[3] L. Formentini,et al. Biochemical Pharmacology 77 (2009) 1612–1620",
        "[4] Stein, L. R., & Imai, S. (2012). The dynamic regulation of NAD metabolism in mitochondria. Trends in Endocrinology & Metabolism, 23(9), 420–428.",
        "[5] NAMPT-mediated NAD+ biosynthesis in adipocytes regulates adipose tissue function and multi-organ insulin sensitivity in mice. Cell Rep. 2016 August 16; 16(7): 1851–1860\n.doi:10.1016/j.celrep.2016.07.027.",
        "[6] Mills et al., Long-Term Administration of Nicotinamide Mononucleotide Mitigates Age-Associated Physiological Decline in Mice. 2016, Cell Metabolism 24, 795–806",
        "[7] Xiaonan Wang, et al. Nicotinamide mononucleotide protects against β-amyloid oligomer-induced cognitive impairment and neuronal death. Brain Research, Volume 1643, 15\n July 2016, Pages 1-9",
        "[8] Jonathan B. Lin, et al. NAMPT-Mediated NAD+ Biosynthesis Is Essential for Vision In Mice. Volume 17, Issue 1, 27 September 2016, Pages 69-85",
        "[9] T Yamamoto, et al. Nicotinamide mononucleotide, an intermediate of NAD+ synthesis, protects the heart from ischemia and reperfusion. PLoS One. 2014; 9(6): e98972.\n https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4048236/",
        "[10] Yellon D M, et al. Myocardial Reperfusion Injury. N Engl J Med. 2007 Sep 13; 357(11): 1121-35. https://www.ncbi.nlm.nih.gov/pubmed/17855673/",
        "[11] Yoshino et al. Cell Metab. 2011 October 5; 14(4): 528–536.  doi: 10.1016/j.cmet.2011.08.014",
    ];
    const [closeList, setCloseList] = useState<boolean>(false); // 얘가 열고접는 스위치역할이 된다.
    const listRef = useRef<HTMLDivElement>(null); // useRef 로 특정 DOM 에 접근할 거다.

    // 클릭시 실행되는 함수
    function foldList() {
        if (!listRef || !listRef.current) {
            // useRef 변수가 비었을 때
            return; // 그냥 리턴하도록 예외처리를 해주자
        }
        const style = listRef.current.style; // 접근할 DOM 요소의 스타일 속성을 미리 선언해둔다.

        if (closeList) {
            // closeAllList 상태변수가 true 일 때
            style.maxHeight = "0"; // maxHeight 는 0이 되고 접힌다.
        } else if (!closeList) {
            // closeAllList 상태변수가 false 면
            style.maxHeight = `${listRef.current.scrollHeight}px`; // maxHeight = scroll 길이가 되고 메뉴가 열린다.
        }
        setCloseList(!closeList); // 클릭할 때마다 상태를 반대로 바꾼다.
    }
    return (
        <div className="reference">
            <div className="reference-title" onClick={foldList}>
                <span>Reference</span>
                <img width={18} height={10} src={arrowDown} alt="" className={`${closeList ? "close" : "open"}`} />
            </div>
            <div className="reference-detail" ref={listRef}>
                {data.map((item, index) => (
                    <p key={index}>{item}</p>
                ))}
            </div>
        </div>
    );
}

export default Reference;
