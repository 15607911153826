import { RoutesString } from "../../Modules/routesString";
import PageErr from "../404/PageErr";
import Home from "../Home/Home";
import Study from "../Study/index";
import Shop from "../Shop/Shop";
import SuperCondria from "../SuperCondria/SuperCondria";
import FAQ from "../FAQ/FAQ";
// import Contacts from '../Contacts/Contacts';
// import Teams from '../Teams/Teams';
// import Works from '../Works/Works';

const pages = [
    {
        path: RoutesString.PageNotFound,
        component: PageErr,
        exact: true,
    },

    /* Home */
    {
        path: RoutesString.Home,
        component: Home,
        exact: true,
    },
    {
        path: RoutesString.Study,
        component: Study,
        exact: true,
    },
    {
        path: RoutesString.SuperCondria,
        component: SuperCondria,
        exact: true,
    },
    {
        path: RoutesString.Shop,
        component: Shop,
        exact: true,
    },
    {
        path: RoutesString.FAQ,
        component: FAQ,
        exact: true,
    },
    /* Works */
    // {
    //   path: RoutesString.Works,
    //   component: Works,
    //   exact: true,
    // },

    // /* Teams */
    // {
    //   path: RoutesString.Teams,
    //   component: Teams,
    //   exact: true,
    // },

    // /* Contacts */
    // {
    //   path: RoutesString.Contacts,
    //   component: Contacts,
    //   exact: true,
    // },
];

export default pages;
